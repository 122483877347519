import React from 'react';

import Page from '@/components/layout/page';
import { withAuth } from '@/components/with_auth';
import Dashboard from '@/components/index/Dashboard';
import routes from '@/services/routes';
import Link from '@/components/commons/link';
import Account from '@/components/commons/icons/Account';
import ThumbsUpOutline from '@/components/commons/icons/ThumbsUpOutline';
import ShareOne from '@/components/commons/icons/ShareOne';
import Roadmap from '@/components/commons/icons/Roadmap';
import UserAdd from '@/components/commons/icons/UserAdd';

import styles from '@/components/index/Index.module.css';

function App() {
    return (
        <Page>
            <div className={`flex flex-wrap pl-4 sm:pl-8 pr-0 sm:pr-6 md:pr-0 ${styles.home}`}>
                <div className="flex-2 w-full pt-8 pb-8 pr-4 md:pr-10">
                    <div className={styles.homeContent}>
                        <div className={styles.homeStats}>
                            <Dashboard />
                        </div>
                    </div>
                </div>
                <div className="w-full py-0 mr-4 sm:mr-0 mt-6 md:mt-0 pr-2 md:py-0 md:w-80 bg-cleanSlate">
                    <div className="w-full md:min-w-48 p-4 pt-12">
                        <h3 className="text-shadeStoneGray text-[16px] font-semibold">What&apos;s next?</h3>
                        <div className={styles.homeTodoLinkList}>
                            <div className={styles.rightPanelItem}>
                                <Link
                                    className="flex items-center"
                                    to={routes.ACCOUNTS}
                                >
                                    <div>
                                        <Account className="text-cleanSlate" />
                                    </div>
                                    <span className="pl-[5px] text-cleanSlate text-[14px] font-semibold">Connect your other accounts</span>
                                </Link>
                            </div>
                            <div className={styles.rightPanelItem}>
                                <Link
                                    className="flex items-center"
                                    to={routes.HELP_PAGES.GETTING_STARTED}
                                    target="_blank"
                                >
                                    <div>
                                        <ThumbsUpOutline className="h-[15px] w-[15px] text-cleanSlate" />
                                    </div>
                                    <span className="pl-[5px] text-cleanSlate text-[14px] font-semibold">Learn how to get started.</span>
                                </Link>
                            </div>
                            <div className={styles.rightPanelItem}>
                                <Link
                                    className="flex items-center"
                                    to="mailto:support@sixty.inc?subject=Feedback"
                                >
                                    <div>
                                        <ShareOne />
                                    </div>
                                    <span className="pl-[5px] text-cleanSlate text-[14px] font-semibold">Share your feedback?</span>
                                </Link>
                            </div>
                            <div className={styles.rightPanelItem}>
                                <Link
                                    className="flex items-center"
                                    to="mailto:support@sixty.inc?subject=Roadmap&amp;body=I'd%20love%20you%20to%20build%20the%20following:"
                                >
                                    <div>
                                        <Roadmap />
                                    </div>
                                    <span className="pl-[5px] text-cleanSlate text-[14px] font-semibold">Help us build our roadmap</span>
                                </Link>
                            </div>
                            <div className={styles.rightPanelItem}>
                                <a
                                    className="flex items-center"
                                    href="mailto:support@sixty.inc?subject=Invite%20Request&amp;body=Please%20can%20I%20invite%20my%20buddy%20<insert%20name>%20to%20be%20a%20beta%20tester?%20His%20email%20address%20is%20<insert email>"
                                >
                                    <div>
                                        <UserAdd />
                                    </div>
                                    <span className="pl-[5px] text-cleanSlate text-[14px] font-semibold">Invite a friend</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
export default withAuth(App);
